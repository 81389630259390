<template>
  <mi-text-field
    ref="miTextField"
    :model-value="modelValue"
    :rules="searchFieldRules"
    class="search-field"
    data-cy="search-field"
    lazy-rules="ondemand"
    debounce="300"
    placeholder="Insert Options, Choices, Components and/or Component Variants"
    hint="To search by more than one parameter use ';' delimiter"
    clearable
    outlined
    @clear="$emit('update:model-value', '')"
    @focus="$emit('toggle:search-box', true)"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template #prepend>
      <!-- Selected element type -->
      <mi-chip
        v-if="selectedTypeLabel"
        :label="selectedTypeLabel"
        class="q-ml-none"
        removable
        @remove="$emit('remove:selected-type')"
      ></mi-chip>

      <mi-chip v-else class="q-ml-none" label="All elements"></mi-chip>

      <!-- Search icon -->
      <mi-icon class="q-ml-xs" name="search" size="18px"></mi-icon>
    </template>
  </mi-text-field>
</template>

<script>
  import { required, minLength } from '@/utils/validators'

  export default {
    name: 'SearchBoxField',
    props: {
      isSearchBoxExpanded: {
        type: Boolean,
        required: false,
        default: false
      },
      modelValue: {
        type: String,
        required: true
      },
      selectedTypeLabel: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['update:model-value', 'remove:selected-type', 'toggle:search-box'],
    data: () => ({
      searchFieldRules: [
        required,
        minLength(3)
      ]
    }),
    watch: {
      modelValue(current = '') {
        if (!current.trim().length) {
          this.removeSearchQueryFromRouteParams()
        }
      }
    },
    methods: {
      blur() {
        this.$refs.miTextField?.blur?.()
      },
      focus() {
        this.$refs.miTextField?.focus?.()
      },
      removeSearchQueryFromRouteParams() {
        const query = { ...this.$route.query }

        delete query.searchString

        this.$router.replace({ query })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-field {
    ::v-deep(.q-field__control),
    ::v-deep(.q-field__after) {
      background-color: $mi-white;
    }

    &.q-field--outlined ::v-deep(.q-field__control) {
      padding-left: .75rem;
      padding-right: .75rem;

      &:before,
      &:after {
        transition: none;
      }
    }

    ::v-deep(.q-field__after) {
      padding-right: .5rem;
      border-width: 2px 2px 2px 0;
      border-style: solid;
      border-color: $mi-field-border-color;
    }

    ::v-deep(.q-field__bottom) {
      padding-top: 6px;
    }

    &:not(.q-field--error) ::v-deep(.q-field__bottom) {
      color: $mi-anthracite-600;
    }

    &.q-field--error ::v-deep(.q-field__after) {
      border-color: $mi-red-700;
    }

    &:not(.q-field--error):hover ::v-deep(.q-field__control):before,
    &:not(.q-field--error):hover ::v-deep(.q-field__control):after,
    &:not(.q-field--error):hover ::v-deep(.q-field__after) {
      border-color: $mi-anthracite-800;
    }
  }
</style>
