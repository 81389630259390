<template>
  <mi-tab-panel class="search-results row no-wrap q-gutter-md q-pa-none">
    <!-- Search results -->
    <div class="col full-height">
      <search-results-table
        v-model="searchStringElements"
        :header-color="currentResultsType.color"
        :items="filteredElements"
        :show-no-data-found="isSearchPerformed && !filteredElements.length"
      >
        <template #default="{ item }">
          <mi-list-item
            :key="item.id"
            :class="{
              'search-results-table__item--selected': currentPreviewElementsIds.includes(item.id),
              [`search-results-table__item--${ currentResultsType.color }`]: true
            }"
            class="items-center"
            clickable
            :disable="isComponentLoading"
            @click="toggleElementInPreview(item)"
          >
            <search-results-item-title :code-id="item.id2" :name="item.name"></search-results-item-title>
            <mi-icon class="q-ml-sm" name="caret-right" size="8px"></mi-icon>
          </mi-list-item>
        </template>
      </search-results-table>
    </div>

    <!-- Preview items -->
    <div class="col full-height">
      <search-results-table
        ref="previewItemsTable"
        v-model="searchStringPreviewElements"
        :header-color="currentPreviewElements.length ? currentResultsType.color : ''"
        :items="filteredPreviewElements"
        :disable="!currentPreviewElements.length"
        :show-no-data-found="!!searchStringPreviewElements?.length && !filteredPreviewElements.length"
      >
        <template #default="{ item }">
          <search-results-group
            :key="item.id"
            :item="item"
            :color="currentResultsType.color"
            :selected-elements-ids="selectedElementsIds"
            :handle-item-click="handleItemClick"
            :handle-sub-item-click="handleSubItemClick"
          ></search-results-group>
        </template>
      </search-results-table>
    </div>
  </mi-tab-panel>
</template>

<script>
  import cloneDeep from 'lodash.clonedeep'
  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

  import { SEARCH_RESULT_TYPES, ELEMENT_STATES } from '@/constants'
  import { TOGGLE_ELEMENT_STATE, TOGGLE_SUB_ELEMENT_STATE } from '@/store/modules/search/mutationTypes'

  import { filterElementByString, filterElementsListByString, getElementTypes } from '@/utils/filterListByString'
  import SearchResultsTable from './SearchResultsTable.vue'
  import SearchResultsGroup from './SearchResultsGroup.vue'
  import SearchResultsItemTitle from './SearchResultsItemTitle.vue'

  export default {
    name: 'SearchResults',
    components: {
      SearchResultsGroup,
      SearchResultsTable,
      SearchResultsItemTitle
    },
    data: () => ({
      ELEMENT_STATES,
      searchStringElements: '',
      searchStringPreviewElements: '',
      isComponentLoading: ''
    }),
    computed: {
      ...mapState('search', ['selectedElementsIds']),
      ...mapState('search', ['areElementsLoading', 'isSearchPerformed', 'selectedResultType']),
      ...mapState('product-model', ['selectedSearchProductModel']),
      ...mapGetters('product-model', ['selectedProductModel', 'hasSelectedSearchProductModel']),
      ...mapGetters('search', ['currentPreviewElements', 'currentPreviewElementsIds', 'currentResultsType']),

      subElementsType() {
        return getElementTypes(this.selectedResultType).subtype
      },
      filteredElements() {
        return Object.freeze(this.currentResultsType?.items.filter(
          element => filterElementByString({ element, searchString: this.searchStringElements })
        )) || []
      },
      filteredPreviewElements() {
        const searchString = this.searchStringPreviewElements
        const previewElements = cloneDeep(this.currentPreviewElements)
        this.componentLoadingState('completed')

        return filterElementsListByString(
          {
            elementsList: previewElements,
            elementType: this.selectedResultType,
            searchString
          }
        )
      }
    },
    watch: {
      areElementsLoading(current) {
        if (current) {
          this.searchStringElements = ''
          this.searchStringPreviewElements = ''
        }
      },
      currentPreviewElementsIds(current, initial) {
        if (current > initial) {
          this.$refs.previewItemsTable?.refreshVirtualScroll?.()
        }
      }
    },
    methods: {
      ...mapActions('search', ['getComponentWithVariants', 'getOptionWithChoices']),
      ...mapMutations('search', { TOGGLE_ELEMENT_STATE, TOGGLE_SUB_ELEMENT_STATE }),
      componentLoadingState(state = '') {
        this.isComponentLoading = (state === 'loading')
      },
      toggleElementInPreview(element = {}) {
        const isInPreview = this.currentPreviewElementsIds.includes(element.id)
        this.componentLoadingState('loading')

        switch (this.selectedResultType) {
          case SEARCH_RESULT_TYPES.OPTIONS:
            this.getOptionWithChoices({ option: element, isInPreview })

            break

          case SEARCH_RESULT_TYPES.CHOICES:
            this.TOGGLE_SUB_ELEMENT_STATE({
              subElement: element,
              subElementsType: SEARCH_RESULT_TYPES.CHOICES,
              subElementState: ELEMENT_STATES.IN_PREVIEW,
              elementsType: SEARCH_RESULT_TYPES.OPTIONS,
              isChosen: isInPreview
            })
            break

          case SEARCH_RESULT_TYPES.COMPONENTS:
            this.getComponentWithVariants({ component: element, isInPreview })
            break

          case SEARCH_RESULT_TYPES.COMPONENT_VARIANTS:
            this.TOGGLE_SUB_ELEMENT_STATE({
              subElement: element,
              subElementsType: SEARCH_RESULT_TYPES.COMPONENT_VARIANTS,
              subElementState: ELEMENT_STATES.IN_PREVIEW,
              elementsType: SEARCH_RESULT_TYPES.COMPONENTS,
              isChosen: isInPreview
            })
            break

          default:
            break
        }
      },
      handleItemClick(elementTypes, { item = {}, isChosen = false } = {}) {
        this.TOGGLE_ELEMENT_STATE({
          element: item,
          elementsType: elementTypes.item,
          elementState: ELEMENT_STATES.SELECTED,
          subElementsType: elementTypes.subItem,
          isChosen
        })
      },

      handleSubItemClick(elementTypes, { subItem = {}, isChosen = false } = {}) {
        this.TOGGLE_SUB_ELEMENT_STATE({
          subElement: subItem,
          subElementsType: elementTypes.subItem,
          subElementState: ELEMENT_STATES.SELECTED,
          elementsType: elementTypes.item,
          isChosen
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-results {
    width: auto;
  }

  .search-results-table__item--selected.search-results-table__item--yellow {
    background-color: lighten($mi-yellow-400, 35%);
  }

  .search-results-table__item--selected.search-results-table__item--green {
    background-color: lighten($mi-green-200, 9%);
  }
</style>
