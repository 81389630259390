<template>
  <div v-if="recentSearchItems.length" class="q-mt-md">
    <!-- Title -->
    <div class="text-subtitle2 text-weight-bold q-px-md"> Recent search </div>

    <!-- Recent search items list -->
    <mi-list class="q-mt-xs">
      <mi-list-item
        v-for="(recentSearchItem, index) in recentSearchItems"
        :key="index"
        class="recent-search-item items-center"
        clickable
        dense
        @click="$emit('click:item', recentSearchItem)"
      >
        <!-- Icon -->
        <mi-list-item-section avatar>
          <mi-icon name="search" size="18px"></mi-icon>
        </mi-list-item-section>

        <!-- Content -->
        <mi-list-item-section class="block ellipsis"> {{ recentSearchItem }} </mi-list-item-section>

        <!-- Remove btn -->
        <mi-list-item-section side>
          <mi-btn
            class="recent-search-item__remove-btn q-px-sm q-pb-xs"
            :ripple="false"
            dense
            flat
            @click.stop="removeRecentSearchItem(recentSearchItem)"
          >
            <mi-icon name="close"></mi-icon>
          </mi-btn>
        </mi-list-item-section>
      </mi-list-item>
    </mi-list>
  </div>
</template>

<script>
  import { RECENT_SEARCH_ITEMS_MAX_AMOUNT } from '@/constants'

  export default {
    name: 'SearchBoxRecentItems',
    emits: ['click:item'],
    data: () => ({
      recentSearchItems: []
    }),
    created() {
      this.recentSearchItems = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_RECENT_SEARCH) || []
    },
    methods: {
      removeRecentSearchItem(recentSearchItem = '') {
        this.recentSearchItems = this.recentSearchItems.filter(searchItem => searchItem !== recentSearchItem)
        this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_RECENT_SEARCH, this.recentSearchItems)
      },
      updateRecentSearchItems(searchStr = '') {
        const previousRecentSearchItems = this.recentSearchItems.filter(searchItem => searchItem !== searchStr)

        if (previousRecentSearchItems.length === RECENT_SEARCH_ITEMS_MAX_AMOUNT) {
          previousRecentSearchItems.pop()
        }

        this.recentSearchItems = [searchStr, ...previousRecentSearchItems]
        this.$q.localStorage.set(process.env.VUE_APP_STORAGE_KEY_RECENT_SEARCH, this.recentSearchItems)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .recent-search-item ::v-deep(.mi-icon) {
    color: $mi-anthracite-400;
  }

  .recent-search-item:hover .recent-search-item__remove-btn {
    opacity: .65;

    &:hover {
      opacity: 1;
    }
  }

  .recent-search-item__remove-btn {
    opacity: 0;

    ::v-deep(.q-focus-helper) {
      display: none;
    }
  }
</style>
