<template>
  <div>
    <span class="label"> {{ label }} </span>
    <span v-if="total" class="label"> ({{ total }}) </span>
  </div>
</template>

<script>
  export default {
    name: 'SearchSummaryTotalElementsLabel',
    props: {
      label: {
        type: String,
        required: true
      },
      total: {
        type: Number,
        required: true
      }
    }

  }
</script>

<style lang="scss" scoped>

  .label {
    color: $mi-silver-700;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
  }

</style>
