<template>
  <section class="q-px-md">
    <!-- Title -->
    <div class="text-subtitle2 text-weight-bold"> Elements </div>

    <!-- Chips -->
    <div class="q-mt-sm">
      <mi-chip
        v-for="elementType in filteredElementTypes"
        :key="elementType.key"
        :label="elementType.label"
        class="q-ml-none"
        clickable
        @click.stop="$emit('click:item', elementType.key)"
      ></mi-chip>
    </div>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const { mapState } = createNamespacedHelpers('search')

  export default {
    name: 'SearchBoxElementTypes',
    props: {
      elementTypes: {
        type: Array,
        required: true
      }
    },
    emits: ['click:item'],
    computed: {
      ...mapState(['selectedElementType']),

      filteredElementTypes() {
        return this.elementTypes.filter(elementType => elementType.key !== this.selectedElementType)
      }
    }
  }
</script>
