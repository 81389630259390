<template>
  <div v-show="showEditSolutionElements" class="drawer-edit-background"></div>
  <div class="search-page flex no-wrap full-width">
    <!-- Search sidebar -->
    <search-sidebar></search-sidebar>
    <div class="search-page__main col-grow">
      <!-- Search box -->
      <search-box class="search-page__search-box"></search-box>

      <section class="search-page__results row no-wrap q-pt-xs q-px-md q-pb-md q-gutter-md">
        <div class="col full-height">
          <!-- Search results filter menu -->
          <div class="flex q-py-xs row">
            <div class="col-6 text-right q-pr-sm">
              <mi-btn
                outline
                dense
                class="col-6 q-mb-sm"
                :disable="!allowAddAll"
                @click="addAllElements"
              >
                Add all {{ selectedResultType === 'componentVariants' ? 'component variants' : selectedResultType }}
              </mi-btn>
            </div>
            <div class="col-6 text-right">
              <search-results-filter-menu></search-results-filter-menu>
            </div>
          </div>

          <!-- Search results -->
          <mi-tab-panels v-model="selectedResultType" :animated="false" class="search-page__content col">
            <search-results :name="SEARCH_RESULT_TYPES.OPTIONS"></search-results>
            <search-results :name="SEARCH_RESULT_TYPES.CHOICES"></search-results>
            <search-results :name="SEARCH_RESULT_TYPES.COMPONENTS"></search-results>
            <search-results :name="SEARCH_RESULT_TYPES.COMPONENT_VARIANTS"></search-results>
          </mi-tab-panels>
        </div>
      </section>
    </div>

    <!-- Summary -->
    <div class="full-height">
      <search-summary ref="searchSummary" class="search-page__summary"></search-summary>

      <mi-resize-observer @resize="handleSearchSummaryResize"></mi-resize-observer>
    </div>

    <!-- Loading -->
    <mi-inner-loading :showing="areElementsLoading">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> Searching for elements. Please wait ... </h6>
    </mi-inner-loading>

    <router-view></router-view>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { SEARCH_RESULT_TYPES } from '@/constants'
  import {
    CLEAR_SEARCH_RESULTS,
    UPDATE_SELECTED_RESULT_TYPE,
    SET_ALL_ELEMENTS, SET_ALL_SUB_ELEMENTS,
    TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE
  } from '@/store/modules/search/mutationTypes'
  import SearchBox from '@/components/search/search-box/SearchBox.vue'
  import SearchSidebar from '@/components/search/SearchSidebar.vue'
  import SearchSummary from '@/components/search/search-summary/SearchSummary.vue'
  import SearchResults from '@/components/search/search-results/SearchResults.vue'
  import SearchResultsFilterMenu from '@/components/search/search-results/SearchResultsFilterMenu.vue'

  const { mapState, mapMutations } = createNamespacedHelpers('search')

  export default {
    name: 'Search',
    components: {
      SearchResults,
      SearchSummary,
      SearchBox,
      SearchSidebar,
      SearchResultsFilterMenu
    },
    data: () => ({ SEARCH_RESULT_TYPES, searchPageSummaryHeight: 0, allowAddAll: false }),
    computed: {
      ...mapState([
        'areElementsLoading',
        'selectedResultType',
        'showEditSolutionElements',
        'isSearchPerformed',
        'elements'
      ])

    },
    created() {
      const { resultType } = this.$route.query || {}

      if (resultType && Object.values(SEARCH_RESULT_TYPES).includes(resultType)) {
        this.UPDATE_SELECTED_RESULT_TYPE(resultType)
      }

      this.$watch('selectedResultType', this.hasElementsToShow)
      this.$watch('elements', this.hasElementsToShow)
    },
    beforeUpdate() {
      this.hasElementsToShow()
    },
    unmounted() {
      this.showEditSolutionElements && this.TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE()
      this.CLEAR_SEARCH_RESULTS()
      this.UPDATE_SELECTED_RESULT_TYPE()
      this.searchPageSummaryHeight = 0
    },
    methods: {
      ...mapMutations({
        CLEAR_SEARCH_RESULTS,
        UPDATE_SELECTED_RESULT_TYPE,
        SET_ALL_ELEMENTS,
        SET_ALL_SUB_ELEMENTS,
        TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE
      }),

      handleSearchSummaryResize({ height } = {}) {
        if (!this.searchPageSummaryHeight) this.searchPageSummaryHeight = height
        this.searchPageSummaryHeight = Math.max(this.searchPageSummaryHeight, 900)
        const pxHeight = `${ this.searchPageSummaryHeight }px`

        this.$refs.searchSummary?.$el?.style?.setProperty('--search-summary-height', pxHeight)
      },
      addAllElements() {
        const isChoicesType = this.selectedResultType === SEARCH_RESULT_TYPES.CHOICES
        const isKvsType = this.selectedResultType === SEARCH_RESULT_TYPES.COMPONENT_VARIANTS
        isChoicesType || isKvsType ? this.SET_ALL_SUB_ELEMENTS(this.selectedResultType)
        : this.SET_ALL_ELEMENTS(this.selectedResultType)
      },
      hasElementsToShow() {
        this.allowAddAll = !!this.elements.structure[this.selectedResultType].totalSize
      }
    }
  }
</script>

<style lang="scss" scoped>
  $search-box-height: 78px;
  $search-filter-menu-height: 38px;

  .drawer-edit-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 65, 65, .6);
    z-index: 99;
  }

  .search-page {
    min-height: inherit;
  }

  .search-page__main {
    height: inherit;
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: #cbd3dc;
  }

  .search-page__search-box {
    height: $search-box-height;
  }

  .search-page__results {
    height: calc(100% - #{$search-box-height});
  }

  .search-page__filter-menu {
    height: $search-filter-menu-height;
  }

  .search-page__content {
    height: calc(100% - #{$search-filter-menu-height});
  }

  .search-page__summary {
    background-color: #ffffff;
  }
</style>
