<template>
  <mi-expansion-item
    :class="`summary-collection--${ color }`"
    class="summary-collection relative-position overflow-auto q-mt-md"
    expand-icon-class="summary-collection__expand-icon"
    header-class="summary-collection__header text-weight-bold"
    default-opened
  >
    <!-- Header -->
    <template #header>
      <mi-list-item-section class="summary-collection__label items-center">
        <slot name="header"></slot>
        <!-- Clear button -->
        <mi-btn
          :ripple="false"
          class="summary-collection__clear-btn q-ml-auto"
          icon="trash"
          icon-size="12px"
          dense
          flat
          icon-type
          fab
          @click.stop="handleClearButtonClick"
        >
          <mi-tooltip anchor="top middle" self="bottom middle"> Clear collection </mi-tooltip>
        </mi-btn>
      </mi-list-item-section>
    </template>

    <!-- Content -->
    <div v-show="items.length" class="summary-collection__content q-pt-sm q-pb-md">
      <mi-list separator bordered>
        <search-results-group
          v-for="item in items"
          :key="item.id"
          :item="item"
          :color="color"
          persistent-title
          removable
          :selected-elements-ids="selectedElementsIds"
          :handle-item-click="handleItemClick"
          :handle-sub-item-click="handleSubItemClick"
        ></search-results-group>
      </mi-list>
    </div>
    <slot name="footer"></slot>
  </mi-expansion-item>
</template>

<script>
  import { createNamespacedHelpers, mapState } from 'vuex'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { ELEMENT_COLORS, ELEMENT_STATES, SEARCH_RESULT_TYPES } from '@/constants'
  import {
    CLEAR_SELECTED_ELEMENTS,
    TOGGLE_ELEMENT_STATE,
    TOGGLE_SUB_ELEMENT_STATE
  } from '@/store/modules/search/mutationTypes'

  import { VM_CLEARED_COLLECTION } from '@/utils/analytics/constants'
  import SearchResultsGroup from '../search-results/SearchResultsGroup.vue'

  const { mapMutations } = createNamespacedHelpers('search')

  export default {
    name: 'SearchSummaryCollection',
    components: { SearchResultsGroup },
    props: {
      color: {
        type: String,
        required: false,
        default: '',
        validator: value => Object.values(ELEMENT_COLORS).includes(value) || !value
      },
      items: {
        type: Array,
        required: false,
        default: () => []
      }
    },
    computed: {
      ...mapState('search', ['selectedElementsIds'])
    },
    methods: {
      ...mapMutations({ CLEAR_SELECTED_ELEMENTS, TOGGLE_ELEMENT_STATE, TOGGLE_SUB_ELEMENT_STATE }),

      handleClearButtonClick() {
        let currentType = ''

        if (this.color === ELEMENT_COLORS.YELLOW) {
          currentType = SEARCH_RESULT_TYPES.OPTIONS
        }
        else if (this.color === ELEMENT_COLORS.GREEN) {
          currentType = SEARCH_RESULT_TYPES.COMPONENTS
        }

        this.CLEAR_SELECTED_ELEMENTS(currentType)

        // Analytics
        recordAnalytics(
          VM_CLEARED_COLLECTION,
          { type: currentType }
        )
      },
      handleItemClick(elementTypes, { item = {}, isChosen = false } = {}) {
        this.TOGGLE_ELEMENT_STATE({
          element: item,
          elementsType: elementTypes.item,
          elementState: ELEMENT_STATES.SELECTED,
          subElementsType: elementTypes.subItem,
          isChosen
        })
      },

      handleSubItemClick(elementTypes, { subItem = {}, isChosen = false } = {}) {
        this.TOGGLE_SUB_ELEMENT_STATE({
          subElement: subItem,
          subElementsType: elementTypes.subItem,
          subElementState: ELEMENT_STATES.SELECTED,
          elementsType: elementTypes.item,
          isChosen
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .summary-collection {
    min-height: 56px;
    font-size: .875rem;
    border-left: 7px solid transparent;
    background-color: $mi-white;
    box-shadow: 0 0 0 1px $mi-silver-300;

    ::v-deep(.summary-collection__header) {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $mi-white;
    }

    &__clear-btn {
      margin-top: 2px;
      margin-right: -10px;

      ::v-deep(.mi-icon) {
        margin-right: 0;
      }
    }

    &__label {
      flex-direction: row;
      justify-content: flex-start;
    }

    &__content {
      padding-left: .75rem;
      padding-right: .75rem;
    }

    ::v-deep(.summary-collection__expand-icon) .q-expansion-item__toggle-icon {
      font-size: 9px;
    }
  }

  .summary-collection--yellow {
    border-left-color: $mi-yellow-400;
  }

  .summary-collection--green {
    border-left-color: $mi-green-500;
  }
</style>
