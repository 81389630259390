<template>
  <mi-btn
    :ripple="false"
    class="search-results-filter-button"
    icon="dots-horizontal"
    flat
    fab
    icon-type
    dense
  >
    <mi-menu :offset="[0, 5]" anchor="bottom right" self="top right">
      <mi-list separator>
        <!-- Show code ID -->
        <mi-list-item :class="{ 'no-pointer-events': !resultsFilterSearch.showName }" tag="label">
          <mi-list-item-section class="q-pr-sm" avatar>
            <mi-checkbox
              :model-value="resultsFilterSearch.showCodeId"
              @update:model-value="UPDATE_RESULTS_FILTER(RESULTS_FILTER.SHOW_CODE_ID)"
            ></mi-checkbox>
          </mi-list-item-section>
          <mi-list-item-section> Show Code ID </mi-list-item-section>
        </mi-list-item>

        <!-- Show name -->
        <mi-list-item :class="{ 'no-pointer-events': !resultsFilterSearch.showCodeId }" tag="label">
          <mi-list-item-section class="q-pr-sm" avatar>
            <mi-checkbox
              :model-value="resultsFilterSearch.showName"
              @update:model-value="UPDATE_RESULTS_FILTER(RESULTS_FILTER.SHOW_NAME)"
            ></mi-checkbox>
          </mi-list-item-section>
          <mi-list-item-section> Show Name </mi-list-item-section>
        </mi-list-item>
      </mi-list>
    </mi-menu>
  </mi-btn>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { RESULTS_FILTER } from '@/constants'
  import { UPDATE_RESULTS_FILTER } from '@/store/modules/search/mutationTypes'

  const { mapState, mapMutations } = createNamespacedHelpers('search')

  export default {
    name: 'SearchResultsFilterMenu',
    data: () => ({ RESULTS_FILTER }),
    computed: {
      ...mapState(['resultsFilterSearch'])
    },
    methods: {
      ...mapMutations({ UPDATE_RESULTS_FILTER })
    }
  }
</script>

<style lang="scss" scoped>
  .search-results-filter-button.mi-btn.q-btn--fab.q-btn--dense {
    padding: 2px;
    margin-right: -4px;
    width: 30px;
    min-width: 30px;
    height: 30px;

    ::v-deep(.q-icon) {
      margin: 0;
    }
  }
</style>
