<template>
  <section class="search-box-container row items-start">
    <div class="col-6">
      <mi-form
        ref="miForm"
        v-click-outside="() => toggleSearchBox(false)"
        :class="{ 'search-box--expanded': isSearchBoxExpanded }"
        class="search-box relative-position"
        no-error-focus
        @validation-error="toggleSearchBox(false)"
        @submit="searchElements"
      >
        <!-- Search field -->
        <search-box-field
          ref="searchBoxField"
          v-model="searchQuery"
          :is-search-box-expanded="isSearchBoxExpanded"
          :selected-type-label="currentElementType.label"
          @remove:selected-type="toggleSelectedElementType"
          @toggle:search-box="toggleSearchBox"
        ></search-box-field>

        <!-- Options -->
        <div v-show="isSearchBoxExpanded" class="search-options absolute-top-left full-width bg-white q-pt-md q-pb-sm">
          <!-- Element types -->
          <search-box-element-types
            :element-types="elementTypes"
            @click:item="toggleSelectedElementType"
          ></search-box-element-types>

          <!-- Recent search -->
          <search-box-recent-items ref="recentSearch" @click:item="applyRecentSearchItem"></search-box-recent-items>

          <!-- Submit button -->
          <div class="flex justify-end q-pt-sm q-pb-xs q-px-md">
            <mi-btn color="accent" type="submit" data-cy="btn-submit"> Refine search </mi-btn>
          </div>
        </div>
      </mi-form>
    </div>

    <!-- Product model selection -->
    <product-model-select class="q-ml-sm"></product-model-select>
  </section>
</template>

<script>
  import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { base64EncodeUnicode } from '@/utils/base64'
  import { SEARCH_ELEMENT_TYPES, SEARCH_STRING_MIN_LENGTH, PM_TYPE } from '@/constants'
  import {
    CLEAR_SEARCH_RESULTS,
    SET_ELEMENTS_LOADING_STATE,
    SET_SEARCH_PERFORMED_STATE, UPDATE_SELECTED_ELEMENT_TYPE
  } from '@/store/modules/search/mutationTypes'

  import { VM_RECENT_SEARCH, VM_SEARCH } from '@/utils/analytics/constants'
  import ProductModelSelect from '@/components/product-model/ProductModelSelect.vue'
  import SearchBoxElementTypes from './SearchBoxElementTypes.vue'
  import SearchBoxField from './SearchBoxField.vue'
  import SearchBoxRecentItems from './SearchBoxRecentItems.vue'

  export default {
    name: 'SearchBox',
    components: {
      ProductModelSelect,
      SearchBoxField,
      SearchBoxElementTypes,
      SearchBoxRecentItems
    },
    data() {
      return {
        isSearchBoxExpanded: false,
        searchQuery: '',
        elementTypes: [
          { key: SEARCH_ELEMENT_TYPES.STRUCTURE, label: 'Structure Elements', action: this.getStructureElements }
        ]
      }
    },
    computed: {
      ...mapState('search', ['selectedElementType']),
      ...mapState('product-model', ['selectedSearchProductModel']),
      ...mapGetters('product-model', ['hasSelectedSearchProductModel']),

      currentElementType() {
        return this.elementTypes.find(elementType => elementType.key === this.selectedElementType) || {}
      }
    },
    created() {
      const { elementType = '', searchString = '' } = this.$route.query || {}
      const elementTypeKeys = this.elementTypes.map(type => type.key)

      if (elementTypeKeys.includes(elementType)) {
        this.UPDATE_SELECTED_ELEMENT_TYPE(elementType)
      }

      if (searchString.trim().length >= SEARCH_STRING_MIN_LENGTH) {
        this.searchQuery = searchString
        this.searchElements()
      }
    },
    unmounted() {
      this.SET_SEARCH_PERFORMED_STATE()
      this.UPDATE_SELECTED_ELEMENT_TYPE()
    },
    methods: {
      ...mapActions('search', [
        'getChoices',
        'getComponentVariants',
        'getComponents',
        'getOptions'
      ]),
      ...mapMutations('search', {
        CLEAR_SEARCH_RESULTS,
        SET_ELEMENTS_LOADING_STATE,
        SET_SEARCH_PERFORMED_STATE,
        UPDATE_SELECTED_ELEMENT_TYPE
      }),

      async searchElements() {
        const trimmedSearchQuery = this.searchQuery.trim()

        const mainPm = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_PRODUCT_MODEL)
        const vmPm = this.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_VM_PRODUCT_MODEL)

        const productModel = vmPm || mainPm || {}

        let params = {}
        if (productModel.productModelType === PM_TYPE.USER_EXPORTS) {
          params = {
            query: this.formatSearchQueryParam(trimmedSearchQuery),
            pmIdentifier: productModel.id,
            pmType: productModel.productModelType || PM_TYPE.PRODUCT_MODEL
          }
        }
        else {
          params = {
            query: this.formatSearchQueryParam(trimmedSearchQuery),
            pmIdentifier: productModel.encodedBusinessName,
            pmType: productModel.productModelType || PM_TYPE.PRODUCT_MODEL
          }
        }

        this.toggleSearchBox()

        this.$router.replace({ query: { ...this.$route.query, searchString: trimmedSearchQuery } })
        this.$refs.recentSearch?.updateRecentSearchItems?.(trimmedSearchQuery)

        this.SET_ELEMENTS_LOADING_STATE(true)
        this.CLEAR_SEARCH_RESULTS()

        try {
          if (this.currentElementType.key) {
            await this.currentElementType.action(params)
          }
          else {
            await Promise.all([this.getStructureElements(params)])
          }

          this.SET_SEARCH_PERFORMED_STATE(true)

          // Analytics
          recordAnalytics(
            VM_SEARCH,
            { searchString: params.query }
          )
        }
        finally {
          this.SET_ELEMENTS_LOADING_STATE()
        }
      },
      async getStructureElements(params) {
        await Promise.all([
          this.getChoices(params),
          this.getComponentVariants(params),
          this.getComponents(params),
          this.getOptions(params)
        ])
      },
      applyRecentSearchItem(recentSearchItem = '') {
        this.searchQuery = recentSearchItem

        this.$refs.miForm?.resetValidation?.()
        this.searchElements()

        // Analytics
        recordAnalytics(
          VM_RECENT_SEARCH,
          { searchString: recentSearchItem }
        )
      },
      formatSearchQueryParam(searchStr = '') {
        return searchStr
          .split(';')
          .map(item => base64EncodeUnicode(item.trim()))
          .filter(item => item.length).join(',') || ''
      },
      toggleSelectedElementType(type = '') {
        const query = { ...this.$route.query }

        this.UPDATE_SELECTED_ELEMENT_TYPE(type)
        delete query.elementType

        if (type) {
          query.elementType = type
        }

        this.toggleSearchBox(true)
        this.$router.replace({ query })
      },
      toggleSearchBox(isExpanded = false) {
        this.isSearchBoxExpanded = isExpanded

        if (isExpanded) {
          this.$refs.searchBoxField?.focus?.()
        }
        else {
          this.$refs.searchBoxField?.blur?.()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-box-container {
    background-color: $mi-anthracite-50;
    padding: .75rem 1rem 6px;
  }

  .search-box--expanded ::v-deep(.q-field) {
    &:not(.q-field--error) {
      .q-field__control:before,
      .q-field__control:after,
      .q-field__after {
        border-color: $mi-anthracite-800;
      }
    }

    &.q-field--error + .search-options {
      border-color: $mi-red-700;
    }

    .q-field__control:before,
    .q-field__control:after,
    .q-field__after {
      border-bottom-color: transparent;
    }
  }

  .search-options {
    top: 38px;
    border-width: 0 2px 2px;
    border-style: solid;
    border-color: $mi-anthracite-900;
    z-index: 11;
  }
</style>
